import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomerRoutes } from "../../../../routes/reportsroutes";

interface Props {
  userDetails?: any;
}

export const CustomerTabs = (props: Props) => {
  const params = useParams();
  const [customerByIdData, setCustomerByIdData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname.split("/");
  const userRole: any = sessionStorage?.getItem("user_role");

  const tabs = [
    {
      id: "1",
      name: "Customer Wise Reports",
      link: `/dashboard/reports/customer-reports`,
      allowedRoles: ["SUPERADMIN"],
    },
    {
      id: "2",
      name: "Payment Partner Wise Reports",
      link: `/dashboard/reports/payment-partner-reports`,
      allowedRoles: ["SUPERADMIN"],
    },
    {
      id: "3",
      name: "Subscribers",
      link: `/dashboard/reports/subscribers`,
      allowedRoles: ["SUPERADMIN", "CUSTOMER"],
    },
    {
      id: "4",
      name: "Subscriptions",
      link: `/dashboard/reports/subscriptions`,
      allowedRoles: ["SUPERADMIN", "CUSTOMER"],
    },
    // {
    //   id: "2",
    //   name: "Site Wise Reports",
    //   link: `/dashboard/reports/site-reports`,
    //   allowedRoles: ["SUPERADMIN", "CUSTOMER"],
    // },
    // {
    //   id: "4",
    //   name: "Service Analysis",
    //   link: `/dashboard/reports/service`,
    //   allowedRoles: ["SUPERADMIN", "CUSTOMER"],
    // },
    // {
    //   id: "5",
    //   name: "Revenue Reports",
    //   link: `/dashboard/reports/revenue`,
    //   allowedRoles: ["SUPERADMIN", "CUSTOMER"],
    // },
    // {
    //   id: "6",
    //   name: "ROI Reports",
    //   link: `/dashboard/reports/roi`,
    //   allowedRoles: ["SUPERADMIN", "CUSTOMER"],
    // },
  ];

  useEffect(() => {
    getUsersById(params?.uuid);
  }, []);

  const getUsersById = (data: any) => { };

  return (
    <div className="container-fluid px-0 content-bg-color box-shadow">
      <div className="row px-4">
        <div className="col-md-12 mt-3">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            {tabs.map((data: any, index: number) => {
              // Check if the tab is allowed for the current user role
              if (
                data.allowedRoles.includes(userRole)
              ) {
                return (
                  <NavLink
                    key={index}
                    to={data?.link}
                    className={`nav-link text-dark px-4 fs-13 ${currentPath.length === 3 &&
                      data?.name === (userRole === "CUSTOMER" ? "Site Wise Reports" : "Customer Wise Reports")
                      ? "active"
                      : ""
                      }`}
                  >
                    {data?.name}
                  </NavLink>
                );
              }
              return null;
            })}
          </ul>
          <div className="tab-content" id="myTabContent">
            <CustomerRoutes />
          </div>
        </div>
      </div>
    </div>
  );
};
