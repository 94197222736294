import { DataTableCol } from "../../../../components/data-table/types";

export const PaymentPartnerReportsDataGridCols: DataTableCol[] = [
  {
    title: "Payment Partner",
    control: "payment_partner_code",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Total Sites",
    control: "total_sites",
    sortable: true,
    canShowColumn: true,
    // isLink: true,

  },
  {
    title: "Active Subscribers",
    control: "active_subscribers",
    isLink: true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Active Subscriptions",
    control: "active_subscriptions",
    isLink: true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Total Subscriptions",
    control: "total_subscriptions",
    isLink: true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Total Amount",
    control: "total_amount",
    sortable: true,
    canShowColumn: true,
    isRounded: true
  }
];
