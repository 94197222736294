import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { DataTable } from '../../../../../components/data-table';
import { CustomerReportsDataGridCols } from '../customer-reports-data-grid-cols';
import Chart from "react-apexcharts";
import { DynamicForm } from '../../../../../components/form-builder/form';
import { GET_CUSTOMER_REPORTS } from '../../../../../app/services/reports.service';
import { Loader } from '../../../../../components/loader';
import { GET_USERS } from '../../../../../app/services/users.service';
import { GET_SITES } from '../../../../../app/services/sites.service';
import { GET_SUBSCRIPTIONS } from '../../../../../app/services/subscriptions.service';
import { CustomerPaymentPartnerDataGridCols } from '../payment-partners-data-grid-cols';
import { SubscriptionTypesReportsDataGridCols } from '../subscription_types-data-grid-cols';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import { CustomerSitesDataGridCols } from './sites-data-grid-cols';

export const CustomerSitesReports = () => {
    const [tableListData, setTableListData] = useState<any>([]);
    const params = useParams();

    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
        customer_uuid: params?.customer
    });
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [subscriptionTypesData, setSubscriptionTypesData] = useState<any>();
    const [paymentPartnersData, setPaymentPartnersData] = useState<any>();
    const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false);
    const [showPaymentPartnerDetailsPopup, setShowPaymentPartnerDetailsPopup] = useState<boolean>(false);
    const [customerNames, setCustomerNames] = useState<string[]>([]);
    const [subscriptionAmounts, setSubscriptionAmounts] = useState<number[]>([]);
    const [totalSubscriptionCounts, setTotalSubscriptionCounts] = useState<number[]>([]);
    const [totalSitesCount, setTotalSitesCount] = useState<number[]>([]);

    const [reportsSearchForm, setReportsSearchForm] = useState<any>([
        {
            title: "input",
            type: "input",
            label: "From Date",
            name: "search",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-4 d-flex justify-content-start mt-3 pt-3",
            buttons: [
                {
                    title: 'Search',
                    action: 'add',
                    class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-1'
                }
            ]
        },
    ]);

    const [optionsMixedChart, setOptionsMixedChart] = useState({
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "50%",
            },
        },
        stroke: {
            width: [4, 0, 0],
        },
        xaxis: {
            categories: customerNames,
        },
        markers: {
            size: 6,
            strokeWidth: 3,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 8,
            },
        },
        yaxis: {
            tickAmount: 5,
            min: 0,
            // max: 5000,
            labels: {
                formatter: function (value: any) {
                    return Math.round(value).toString(); // Rounds to nearest integer
                },
            },
        },
        plugins: {
            legend: {
                position: "top", // Placing legend at the top
            },
        },
    });

    const [seriesMixedChart, setSeriesMixedChart] = useState([
        {
            name: "Total Subscription Amount",
            type: "line",
            data: subscriptionAmounts,
        },
        {
            name: "Total Subscriptions",
            type: "column",
            data: totalSubscriptionCounts,
        },
        {
            name: "Sites",
            type: "column",
            data: totalSitesCount,
        },
    ]);


    useEffect(() => {
        getCustomerReports(pageCount);
        // getCustomers();
    }, [])

    const getCustomerReports = (pageData: any) => {
        setLoading(true);
        GET_CUSTOMER_REPORTS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setTableListData({ rows: res?.data?.rows && res?.data?.rows[0]?.sites, total: res?.data?.rows && res?.data?.rows[0]?.sites.length });
                    // const customerData = res.data.rows;
                    // const names: any = customerData.map((item: any) => item.customer_name);
                    // const subscriptionAmounts: any = customerData.map((item: any) => item.total_amount);
                    // const sitesCount: any = customerData.map((item: any) => item.total_sites);
                    // const totalSubscriptionCounts: any = customerData.map((item: any) => item.total_subscriptions);

                    // setCustomerNames([...names]);
                    // setSubscriptionAmounts([...subscriptionAmounts]);
                    // setTotalSubscriptionCounts([...totalSubscriptionCounts]);
                    // setTotalSitesCount([...sitesCount])

                    // setOptionsMixedChart((prevOptions: any) => ({
                    //     ...prevOptions,
                    //     xaxis: {
                    //         ...prevOptions.xaxis,
                    //         categories: names
                    //     }
                    // }));

                    // setSeriesMixedChart([
                    //     { name: "Total Subscription Amount", type: "line", data: subscriptionAmounts },
                    //     { name: "Total Subscriptions", type: "column", data: totalSubscriptionCounts },
                    //     { name: "Sites", type: "column", data: sitesCount }
                    // ]);
                    setLoading(false);
                }
            }
        });
    }

    const getSites = (customerUuid: any) => {
        setLoading(true);
        GET_SITES({ limit: 1000, page: 0, user_uuid: customerUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const sitesData = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        sitesData.push({ label: element?.code, value: element?.uuid })
                    }
                    const data = reportsSearchForm
                    data[1].option = sitesData
                    setReportsSearchForm([...data])
                    setLoading(false);
                }
            }
        });
    }

    const getSubscriptions = (siteUuid: any) => {
        setLoading(true);
        GET_SUBSCRIPTIONS({ limit: 1000, page: 0, site_uuid: siteUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const subscriptionsData = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        subscriptionsData.push({ label: element?.plan_name, value: element?.uuid })
                    }
                    const data = reportsSearchForm
                    data[2].option = subscriptionsData
                    setReportsSearchForm([...data])
                    setLoading(false);
                }
            }
        });
    }

    const exportProgramData = () => {
        setLoading(true);
        GET_CUSTOMER_REPORTS({ limit: tableListData?.total, page: 0, customer_uuid: params?.customer }).then((res: any) => {
            if (res.status === "error") {
                toast.error(res.message, { position: 'top-right' });
                setLoading(false);
            } else {
                if (res) {
                    const data = res.data?.rows;
                    const userData: any = [];
                    data[0]?.sites.forEach((element: any) => {
                        userData.push({
                            "Site Name": element?.site_name,
                            "Active Subscribers": element?.active_subscribers,
                            "Active Subscriptions": element?.active_subscriptions,
                            "Total Subscriptions": element?.total_subscriptions,
                            "Total Amount": element?.total_amount
                                ? Object.entries(element.total_amount).map(([key, value]) => (
                                    `${Math.round(value as number)} ${key}`
                                )).join(", ")
                                : "N/A"
                        });
                    });

                    const worksheet = XLSX.utils.json_to_sheet(userData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                    XLSX.writeFile(workbook, `customer_reports.xlsx`);
                    setLoading(false);
                };
            }
        });
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const pageData = pageCount;
            pageData.page = 0
            const formDataObj = { ...pageData, ...data?.formData };
            delete formDataObj['undefined'];
            for (var propName in formDataObj) {
                if (!formDataObj[propName]) {
                    delete formDataObj[propName];
                }
            }
            const searchData = formDataObj
            searchData.page = 0
            setPageCount({ ...searchData })
            getCustomerReports(formDataObj)
        } else if (data?.type === "cancel") {
            const formData = reportsSearchForm;
            formData?.forEach((element: any) => {
                element.value = ""
            })
            setReportsSearchForm([...formData]);
            const pageData = {
                limit: 10,
                page: 0,
            }
            getCustomerReports(pageData);
        }
    };

    const onChangeDropdown = (event: any) => {
        if (event.name === 'customer_uuid') {
            getSites(event.value)
        } else if (event.name === 'site_uuid') {
            getSubscriptions(event.value)
        }
    }

    const onActions = (data: any) => {
        if (data.type === "link") {
            switch (data?.data?.column) {
                case "site_name":
                    navigate(`/dashboard/reports/subscriptions?site=${data?.data?.site_uuid}&&customer=${data?.data?.customer_uuid}`);
                    break;
                case "active_subscribers":
                    navigate(`/dashboard/reports/subscribers?site=${data?.data?.site_uuid}&&customer=${data?.data?.customer_uuid}`);
                    break;
                case "active_subscriptions":
                    navigate(`/dashboard/reports/subscriptions?site=${data?.data?.site_uuid}&&customer=${data?.data?.customer_uuid}`);
                    break;
                case "total_subscriptions":
                    navigate(`/dashboard/reports/subscriptions?site=${data?.data?.site_uuid}&&customer=${data?.data?.customer_uuid}`);
                    break;
                default:
                    // Handle default case (if none of the above match)
                    break;
            }
            console.log('data.type', data);

            // navigate(`/dashboard/reports/service?`);
            // navigate(`/dashboard/reports/site-reports?customerUuid=${data?.data?.customer_uuid}`);
        } else if (data.type === "Payment Partner") {
            const paymentPartnersTableData = { total: 10, rows: data?.data?.payment_partners }
            setPaymentPartnersData(paymentPartnersTableData);
            setShowPaymentPartnerDetailsPopup(true);
        } else if (data.type === "Subscription Types") {
            const subscriptionTypesData = { total: 10, rows: data?.data?.subscription_types }
            setSubscriptionTypesData(subscriptionTypesData)
            setShowDetailsPopup(true)
        }
    };
    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getCustomerReports(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getCustomerReports(pageData);
    };

    return (
        <div>
            <div className="col-md-12">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card rounded-0 bg-white">
                                <div className="px-2">
                                    <div className='px-1'>
                                        <div className="card-header text-start py-3">
                                            <i className="bi bi-postcard"></i>{" "}
                                            <span className="text-start "> Customers Sites </span>
                                            <button className="float-end btn btn-primary py-1 border-0" onClick={exportProgramData}>
                                                {" "}
                                                Export <i className="bi bi-cloud-arrow-up"></i>
                                            </button>
                                        </div>
                                        <Loader LoadingReducer={loading} />
                                        <DataTable
                                            tableData={tableListData}
                                            TableCols={CustomerSitesDataGridCols}
                                            onActions={onActions}
                                            onPageChange={onPageChange}
                                            onSelectPageChange={(e) => onSelectPage(e)}
                                            pageCount={pageCount}
                                        />
                                    </div>
                                    {/* <div className="">
                                        <div className="col-12 mixed-chart">
                                            <div className="card-header text-start py-3 ">
                                                <i className="bi bi-postcard"></i>{" "}
                                                <span className="text-start ">Customers Chart </span>
                                            </div>
                                            {optionsMixedChart?.xaxis?.categories?.length > 0 && (
                                                <Chart
                                                    series={seriesMixedChart}
                                                    options={optionsMixedChart}
                                                    type="line"
                                                    height={500}
                                                />
                                            )}
                                            {optionsMixedChart?.xaxis?.categories?.length === 0 && (
                                                <div className='text-center py-5 my-5'>No Data Available</div>
                                            )}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
