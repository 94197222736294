import { DataTableCol } from "../../../../../components/data-table/types";

export const CustomerSitesDataGridCols: DataTableCol[] = [
    {
        title: "Site Name",
        control: "site_name",
        sortable: true,
        isLink: true,
        canShowColumn: true,
    },
    {
        title: "Active Subscribers",
        control: "active_subscribers",
        sortable: true,
        canShowColumn: true,
        isLink: true,
    },
    {
        title: "Active Subscriptions",
        control: "active_subscriptions",
        sortable: true,
        canShowColumn: true,
        isLink: true,
    },
    {
        title: "Total Subscriptions",
        control: "total_subscriptions",
        sortable: true,
        canShowColumn: true,
        isLink: true,
    },
    {
        title: "Total Amount",
        control: "total_amount",
        canShowColumn: true,
        isRounded: true
    }

];
