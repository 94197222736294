import { Route, Routes } from "react-router-dom";
import { ReportsDashboard } from "../pages/dashboard/reports/Service_Analysis/reports-dashboard";
import { RevenueDashboard } from "../pages/dashboard/reports/revenue_reports/revenue-dashboard";
import { RoiDashboard } from "../pages/dashboard/reports/roi_reports/roi-dashboard";
import { ServiceSite } from "../pages/dashboard/reports/Service_Analysis/service_users";
import { RevenueSite } from "../pages/dashboard/reports/revenue_reports/revenue_users";
import { ROISite } from "../pages/dashboard/reports/roi_reports/roi_users";
import { ServiceContent } from "../pages/dashboard/reports/Service_Analysis/service_content";
import { RevenueContentData } from "../pages/dashboard/reports/revenue_reports/revenue_content";
import { ROIContentData } from "../pages/dashboard/reports/roi_reports/roi_content";
import { CustomerReports } from "../pages/dashboard/reports/customer-reports/customer-reports";
import { PaymentPartnerReports } from "../pages/dashboard/reports/payment-partner-reports/payment-partner-reports";
import { SiteReports } from "../pages/dashboard/reports/site-reports/site-reports";
import { SiteSubscriptionsReports } from "../pages/dashboard/reports/site-reports/site-subscriptions/site-subscriptions-reports";
import { SubscriptionsReports } from "../pages/dashboard/reports/subscriptions-reports/subscriptions-reports";
import { SubscribersReports } from "../pages/dashboard/reports/subscribers-reports/subscribers-reports";
import { CustomerSitesReports } from "../pages/dashboard/reports/customer-reports/sites/sites-reports";

export const CustomerRoutes = () => {
  const userRole = sessionStorage?.getItem("user_role");
  return (
    <Routes>
      <Route path="/" element={userRole === "CUSTOMER" ? <SiteReports /> : <CustomerReports />}></Route>
      <Route path="/revenue" element={<RevenueDashboard />}></Route>
      <Route path="/revenue/:site_id/revenue-site" element={<RevenueSite />}></Route>
      <Route path="/revenue/:site_id/revenue-site/:content_id/service-content" element={<RevenueContentData />}></Route>
      <Route path="/service" element={<ReportsDashboard />}></Route>
      <Route path="/service/:site_id/service-site" element={<ServiceSite />} ></Route>
      <Route path="/service/:site_id/service-site/:content_id/service-content" element={<ServiceContent />}></Route>
      <Route path="/roi" element={<RoiDashboard />}></Route>
      <Route path="/roi/:site_id/roi-site" element={<ROISite />}></Route>
      <Route path="/roi/:site_id/roi-site/:content_id/roi-content" element={<ROIContentData />}></Route>
      <Route path="/customer-reports" element={<CustomerReports />}></Route>
      <Route path="/customer-reports/:customer" element={<CustomerSitesReports />}></Route>
      <Route path="/payment-partner-reports" element={<PaymentPartnerReports />}></Route>
      <Route path="/subscribers" element={<SubscribersReports />}></Route>
      <Route path="/subscriptions" element={<SubscriptionsReports />}></Route>

      <Route path="/site-reports" element={<SiteReports />}></Route>
      <Route path="/site-reports/:site_report_uuid/subscriptions" element={<SiteSubscriptionsReports />}></Route>
    </Routes>
  );
};
