import { DataTableCol } from "../../../../components/data-table/types";

export const SubscriptionsReportsDataGridCols: DataTableCol[] = [
  {
    title: "Customer Name",
    control: "customer_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Site Name",
    control: "site_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Payment Partner",
    control: "payment_partner_code",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "User MSISDN",
    control: "user_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Amount",
    control: "amount",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Subscription From",
    control: "start_dt",
    sortable: true,
    canShowColumn: true,
    isDateFormat: true
  },
  {
    title: "Subscription To",
    control: "expiry_dt",
    sortable: true,
    canShowColumn: true,
    isDateFormat: true
    // isRounded: true
  },
  {
    title: "STATUS",
    control: "status",
    sortable: true,
    canShowColumn: true,
    // isRounded: true
  }

  // {
  //   title: "Subscriptions Count",
  //   control: "total_subscriptions",
  //   sortable: true,
  //   canShowColumn: true,
  // },
  // {
  //   title: "Amount",
  //   control: "total_amount",
  //   sortable: true,
  //   canShowColumn: true,
  //   isRounded: true
  // },
  // {
  //   title: "Processed",
  //   control: "processed",
  //   sortable: true,
  //   canShowColumn: true,
  // },
  // {
  //   title: "Status",
  //   control: "status",
  //   sortable: true,
  //   canShowColumn: true,
  // },
];
