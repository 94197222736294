import { DataTableCol } from "../../../../components/data-table/types";

export const SubscribersReportsDataGridCols: DataTableCol[] = [
  {
    title: "Customer Name",
    control: "customer_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Site Name",
    control: "site_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Payment Partner",
    control: "payment_partner_code",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "User MSISDN",
    control: "user_name",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Subscribed ON",
    control: "subscription_start_dt",
    sortable: true,
    canShowColumn: true,
    isDateFormat: true
  },
  {
    title: "Un-Subscribed ON",
    control: "subscription_end_dt",
    sortable: true,
    canShowColumn: true,
    isDateFormat: true
  },
  {
    title: "Status",
    control: "recurring",
    sortable: true,
    canShowColumn: true,
    isRecurring: true
    // isRounded: true
  },
  {
    title: "View Subscriptions",
    control: "total_amount",
    sortable: true,
    canShowColumn: true,
    isShowView: true
    // isRounded: true
  }

  // {
  //   title: "Subscriptions Count",
  //   control: "total_subscriptions",
  //   sortable: true,
  //   canShowColumn: true,
  // },
  // {
  //   title: "Amount",
  //   control: "total_amount",
  //   sortable: true,
  //   canShowColumn: true,
  //   isRounded: true
  // },
  // {
  //   title: "Processed",
  //   control: "processed",
  //   sortable: true,
  //   canShowColumn: true,
  // },
  // {
  //   title: "Status",
  //   control: "status",
  //   sortable: true,
  //   canShowColumn: true,
  // },
];
