import API from "../utils/axios";
let common = "/v1/api";

export const GET_PAYMENT_PARTNER_REPORTS = (data: any): Promise<any> => {
  return API.get(`${common}/reports/payment/partners`, { params: data });
};

export const GET_SITES_REPORTS = (data: any): Promise<any> => {
  return API.get(`${common}/reports/sites`, { params: data });
};

export const GET_CUSTOMER_REPORTS = (data: any): Promise<any> => {
  return API.get(`${common}/reports/customers`, { params: data });
};

export const GET_SITE_SUBSCRIPTION_REPORTS = (
  siteUuid: any,
  data: any
): Promise<any> => {
  return API.get(`${common}/reports/site/${siteUuid}/subscriptions`, {
    params: data,
  });
};

export const GET_SUBSCRIBERS_REPORTS = (data: any): Promise<any> => {
  return API.get(`${common}/reports/subscribers`, { params: data });
};

export const GET_SUBSCRIPTION_REPORTS = (data: any): Promise<any> => {
  return API.get(`${common}/reports/user/subscriptions`, { params: data });
};
