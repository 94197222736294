import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from '../../../../components/data-table';
import Chart from "react-apexcharts";
import { DynamicForm } from '../../../../components/form-builder/form';
import { GET_CUSTOMER_REPORTS, GET_SUBSCRIBERS_REPORTS, GET_SUBSCRIPTION_REPORTS } from '../../../../app/services/reports.service';
import { Loader } from '../../../../components/loader';
import { GET_USERS } from '../../../../app/services/users.service';
import { GET_SITES } from '../../../../app/services/sites.service';
import { GET_SUBSCRIPTIONS } from '../../../../app/services/subscriptions.service';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import { GET_PAYMENT_PARTNERS } from '../../../../app/services/payment-partners.service';
import { SubscriptionsReportsDataGridCols } from './subscriptions-reports-data-grid-cols';
import moment from 'moment';

export const SubscriptionsReports = () => {
    const [tableListData, setTableListData] = useState<any>([]);
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [subscriptionTypesData, setSubscriptionTypesData] = useState<any>();
    const [paymentPartnersData, setPaymentPartnersData] = useState<any>();
    const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false);
    const [showPaymentPartnerDetailsPopup, setShowPaymentPartnerDetailsPopup] = useState<boolean>(false);
    const [customerNames, setCustomerNames] = useState<string[]>([]);
    const [subscriptionAmounts, setSubscriptionAmounts] = useState<number[]>([]);
    const [totalSubscriptionCounts, setTotalSubscriptionCounts] = useState<number[]>([]);
    const [totalSitesCount, setTotalSitesCount] = useState<number[]>([]);
    const [totalSitesData, setTotalSitesData] = useState<number[]>([]);
    const userRole = sessionStorage?.getItem("user_role");
    const userUuid = sessionStorage?.getItem("user_uuid");

    const [reportsSearchForm, setReportsSearchForm] = useState<any>([

        {
            title: "dropdown",
            type: "text",
            label: "Customers",
            name: "customer_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select customer",
            error: "Please select customer",
            hideMandatoryStar: true,
            isParentDropDown: true,
            isApiCall: false,
        },
        {
            title: "dropdown",
            type: "text",
            label: "Sites",
            name: "site_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select site",
            error: "Please select site",
            hideMandatoryStar: true,
            isParentDropDown: true,
            isApiCall: true,
        },
        {
            title: "dropdown",
            type: "text",
            label: "Payment Partner",
            name: "payment_partner_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select Payment Partner",
            error: "Please select Payment Partner",
            hideMandatoryStar: true,
            isParentDropDown: false,
            isApiCall: false,
        },
        {
            title: "dropdown",
            type: "text",
            label: "Subscriptions",
            name: "subscriber_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select Subscriptions",
            error: "Please select Subscriptions",
            hideMandatoryStar: true,
            isParentDropDown: false,
            isApiCall: false,
        },
        {
            title: "input",
            type: "date",
            label: "From Date",
            name: "payment_dt_from",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select from date",
            error: "Please select from date",
            hideMandatoryStar: true,
            max: moment().format('YYYY-MM-DD')
        },
        {
            title: "input",
            type: "date",
            label: "To Date",
            name: "payment_dt_to",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select to date",
            error: "Please select to date",
            hideMandatoryStar: true,
            max: moment().format('YYYY-MM-DD')
        },
        {
            title: "dropdown",
            type: "text",
            label: "Status",
            name: "status",
            option: [
                {
                    label: "ACTIVE", value: 1
                },
                {
                    label: "EXPIRED ", value: 2
                }
            ],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: true,
            isParentDropDown: false,
            isApiCall: false,
        },
        {
            title: "input",
            type: "input",
            label: "search with MSISDN",
            name: "search",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please enter MSISDN",
            error: "Please enter MSISDN",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-3 d-flex justify-content-start mt-3 pt-3",
            buttons: [
                {
                    title: 'Search',
                    action: 'add',
                    class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-1'
                },
                {
                    title: 'Reset',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-1'
                }
            ]
        },
    ]);

    const [optionsMixedChart, setOptionsMixedChart] = useState({
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "50%",
            },
        },
        stroke: {
            width: [4, 0, 0],
        },
        xaxis: {
            categories: customerNames,
        },
        markers: {
            size: 6,
            strokeWidth: 3,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 8,
            },
        },
        yaxis: {
            tickAmount: 5,
            min: 0,
            // max: 5000,
            labels: {
                formatter: function (value: any) {
                    return Math.round(value).toString(); // Rounds to nearest integer
                },
            },
        },
        plugins: {
            legend: {
                position: "top", // Placing legend at the top
            },
        },
    });

    const [seriesMixedChart, setSeriesMixedChart] = useState([
        {
            name: "Total Subscription Amount",
            type: "line",
            data: subscriptionAmounts,
        },
        {
            name: "Total Subscriptions",
            type: "column",
            data: totalSubscriptionCounts,
        },
        {
            name: "Sites",
            type: "column",
            data: totalSitesCount,
        },
    ]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const customerUuid = queryParams.get('customer');
    console.log('queryParams', customerUuid);
    const siteUuid = queryParams.get('site');
    const paymentUuid = queryParams.get('payment');
    const subscriptionUuid = queryParams.get('subscription');

    useEffect(() => {
        getCustomerReports(pageCount);
        if (userRole === "SUPERADMIN") {
            getCustomers();
            getPaymentPartnersData([]);
        }

        if (userRole === "CUSTOMER") {
            const formData = reportsSearchForm;
            formData.splice(0, 1);
            setReportsSearchForm([...formData]);
            getSites(userUuid)
        }

        if (customerUuid) {
            const data = reportsSearchForm;
            data[0].value = customerUuid
            getSites(customerUuid)
            setReportsSearchForm([...data]);
        }
        if (siteUuid) {
            const data = reportsSearchForm;
            data[userRole === "CUSTOMER" ? 0 : 1].value = siteUuid
            setReportsSearchForm([...data]);
            getSubscriptions(siteUuid)
        }
        if (paymentUuid) {
            const data = reportsSearchForm;
            data[userRole === "CUSTOMER" ? 1 : 2].value = paymentUuid
            setReportsSearchForm([...data]);
        }
        if (subscriptionUuid) {
            const data = reportsSearchForm;
            data[userRole === "CUSTOMER" ? 2 : 3].value = subscriptionUuid
            setReportsSearchForm([...data]);
        }
    }, [])

    const getCustomerReports = (pageData: any) => {
        setLoading(true);
        if (userRole === "CUSTOMER") {
            pageData.customer_uuid = userUuid;
        }
        if (customerUuid) {
            pageData.customer_uuid = customerUuid;
        }
        if (siteUuid) {
            pageData.site_uuid = siteUuid;
        }
        if (paymentUuid) {
            pageData.payment_partner_uuid = paymentUuid;
        }
        if (subscriptionUuid) {
            pageData.subscription_uuid = subscriptionUuid;
        }

        GET_SUBSCRIPTION_REPORTS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    const customerData = res.data.rows;
                    const names: any = customerData.map((item: any) => item.customer_name);
                    const subscriptionAmounts: any = customerData.map((item: any) => item.total_amount);
                    const sitesCount: any = customerData.map((item: any) => item.total_sites);
                    const totalSubscriptionCounts: any = customerData.map((item: any) => item.total_subscriptions);

                    setCustomerNames([...names]);
                    setSubscriptionAmounts([...subscriptionAmounts]);
                    setTotalSubscriptionCounts([...totalSubscriptionCounts]);
                    setTotalSitesCount([...sitesCount])

                    setOptionsMixedChart((prevOptions: any) => ({
                        ...prevOptions,
                        xaxis: {
                            ...prevOptions.xaxis,
                            categories: names
                        }
                    }));

                    setSeriesMixedChart([
                        { name: "Total Subscription Amount", type: "line", data: subscriptionAmounts },
                        { name: "Total Subscriptions", type: "column", data: totalSubscriptionCounts },
                        { name: "Sites", type: "column", data: sitesCount }
                    ]);
                    setLoading(false);
                }
            }
        });
    }

    const getCustomers = () => {
        setLoading(true);
        GET_USERS({ limit: 1000, page: 0 }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const customers = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        customers.push({ label: element?.name, value: element?.uuid })
                    }
                    const data = reportsSearchForm
                    data[0].option = customers
                    setReportsSearchForm([...data])
                    setLoading(false);
                }
            }
        });
    }

    const getSites = (customerUuid: any) => {
        setLoading(true);
        GET_SITES({ limit: 1000, page: 0, customer_uuid: customerUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const sitesData = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        sitesData.push({ label: element?.code, value: element?.uuid, payment_partners: element?.payment_partners })
                    }
                    const data = reportsSearchForm
                    data[userRole === "CUSTOMER" ? 0 : 1].option = sitesData;
                    if (siteUuid) {
                        const responseData: any = response.find((data: any) => data?.uuid === siteUuid);
                        getPaymentPartnersData(responseData?.payment_partners);
                    }
                    setTotalSitesData([...response]);
                    setReportsSearchForm([...data]);
                    setLoading(false);
                }
            }
        });
    }

    const getSubscriptions = (siteUuid: any) => {
        setLoading(true);
        GET_SUBSCRIPTIONS({ limit: 1000, page: 0, site_uuid: siteUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const subscriptionsData = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        subscriptionsData.push({ label: element?.plan_name, value: element?.uuid })
                    }
                    const data = reportsSearchForm
                    data[userRole === "CUSTOMER" ? 2 : 3].option = subscriptionsData
                    setReportsSearchForm([...data])
                    setLoading(false);
                }
            }
        });
    }

    const exportProgramData = () => {
        setLoading(true);
        GET_SUBSCRIPTION_REPORTS({ limit: tableListData?.total, page: 0 }).then((res: any) => {
            if (res.status === "error") {
                toast.error(res.message, { position: 'top-right' });
                setLoading(false);
            } else {
                if (res) {
                    const data = res.data?.rows;
                    const userData: any = [];
                    data.forEach((element: any) => {
                        userData.push(
                            {
                                "Customer Name": element?.customer_name,
                                "Site Name": element?.site_name,
                                "Payment Partner": element?.payment_partner_code,
                                "User MSISDN": element?.user_name,
                                "Amount": element?.amount,
                                "Subscription From": element?.start_dt,
                                "Subscription To": element?.expiry_dt,
                                "Status": element?.status === 1 ? "ACTIVE" : "EXPIRED"
                            }
                        );
                    })
                    const worksheet = XLSX.utils.json_to_sheet(userData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                    XLSX.writeFile(workbook, `customer_reports.xlsx`);
                    setLoading(false);
                    setLoading(false);
                };
            }
        });
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const pageData = pageCount;
            pageData.page = 0
            const formDataObj = { ...pageData, ...data?.formData };
            delete formDataObj['undefined'];
            for (var propName in formDataObj) {
                if (!formDataObj[propName]) {
                    delete formDataObj[propName];
                }
            }
            const searchData = formDataObj
            searchData.page = 0
            setPageCount({ ...searchData })
            getCustomerReports(formDataObj)
        } else if (data?.type === "cancel") {
            const formData = reportsSearchForm;
            formData?.forEach((element: any) => {
                element.value = ""
            })
            setReportsSearchForm([...formData]);
            const pageData = {
                limit: 10,
                page: 0,
            }
            getCustomerReports(pageData);
        }
    };

    const onChangeDropdown = (event: any) => {
        if (event.name === 'customer_uuid') {
            getSites(event.value)
        } else if (event.name === 'site_uuid') {
            // getSubscriptions(event.value)
            console.log('event', event);
            const data: any = totalSitesData.find((data: any) => data?.uuid === event.value)
            getPaymentPartnersData(data?.payment_partners)
        }
    }

    const getPaymentPartnersData = (payment_partners: any) => {
        setLoading(true);
        GET_PAYMENT_PARTNERS({ limit: 1000, page: 0 }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    // const filteredData = data.filter(item => payment_partners.includes(item.uuid));

                    const response = userRole === "CUSTOMER" ? res?.data?.rows.filter((item: any) => payment_partners.includes(item.uuid)) : res?.data?.rows;
                    const paymentPartnerData: any = [];
                    response?.forEach((el: any) => (
                        paymentPartnerData?.push({
                            label: `${el?.code.replace('_', ' ')} ${el?.metadata?.currency ? `(${el?.metadata?.currency})` : ''}`,
                            value: el?.uuid
                        })
                    ))


                    const data = reportsSearchForm
                    data[userRole === "CUSTOMER" ? 1 : 2].option = paymentPartnerData;
                    setReportsSearchForm([...data]);
                    // const siteFormData = createSubscription
                    // siteFormData[0].option = paymentPartnerData
                    // setCreateSubscription([...siteFormData]);

                    // const searchForm = searchFormData
                    // searchForm[1].option = paymentPartnerData
                    // setSearchFormData([...searchForm]);

                    setLoading(false);
                }
            }
        });
    }

    const onActions = (data: any) => {
        if (data.type === "link") {
            navigate(`/dashboard/reports/site-reports?customerUuid=${data?.data?.customer_uuid}`);
        } else if (data.type === "Payment Partner") {
            const paymentPartnersTableData = { total: 10, rows: data?.data?.payment_partners }
            setPaymentPartnersData(paymentPartnersTableData);
            setShowPaymentPartnerDetailsPopup(true);
        } else if (data.type === "Subscription Types") {
            const subscriptionTypesData = { total: 10, rows: data?.data?.subscription_types }
            setSubscriptionTypesData(subscriptionTypesData)
            setShowDetailsPopup(true)
        }
    };
    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getCustomerReports(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getCustomerReports(pageData);
    };

    return (
        <div>
            {/* <div className="sub-header-primary-color py-2">
                <div className="h-25 ms-2 text-white border-start border-3 border-success">
                    <div className="text-start ps-3">Customer Reports</div>
                </div>
            </div> */}
            <div className="col-md-12">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card rounded-0 bg-white">
                                <div className="card-body">
                                    <div className="row">
                                        <DynamicForm
                                            userFormData={reportsSearchForm}
                                            columns={4}
                                            cancelRoute={""}
                                            isEdit={false}
                                            custom_button_text={"Login"}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            onParentDropdownEvent={(e) => onChangeDropdown(e)}
                                            customClass={"display-block"}
                                            loading={""}
                                            isNotEmptyFormAfterSubmit={true}
                                        />
                                    </div>
                                </div>
                                <div className="px-2">
                                    <div className='px-1'>
                                        <div className="card-header text-start py-3">
                                            <i className="bi bi-postcard"></i>{" "}
                                            <span className="text-start "> Customers Table </span>
                                            <button className="float-end btn btn-primary py-1 border-0" onClick={exportProgramData}>
                                                {" "}
                                                Export <i className="bi bi-cloud-arrow-up"></i>
                                            </button>
                                        </div>
                                        <Loader LoadingReducer={loading} />
                                        <DataTable
                                            tableData={tableListData}
                                            TableCols={SubscriptionsReportsDataGridCols}
                                            onActions={onActions}
                                            onPageChange={onPageChange}
                                            onSelectPageChange={(e) => onSelectPage(e)}
                                            pageCount={pageCount}
                                        />
                                    </div>
                                    {/* <div className="">
                                        <div className="col-12 mixed-chart">
                                            <div className="card-header text-start py-3 ">
                                                <i className="bi bi-postcard"></i>{" "}
                                                <span className="text-start ">Customers Chart </span>
                                            </div>
                                            {optionsMixedChart?.xaxis?.categories?.length > 0 && (
                                                <Chart
                                                    series={seriesMixedChart}
                                                    options={optionsMixedChart}
                                                    type="line"
                                                    height={500}
                                                />
                                            )}
                                            {optionsMixedChart?.xaxis?.categories?.length === 0 && (
                                                <div className='text-center py-5 my-5'>No Data Available</div>
                                            )}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
