import React, { useEffect, useState } from 'react'
import { RootNavigation } from '../../routes'
import { NavLink, useNavigate } from 'react-router-dom'
import { DashboardRoutes } from '../../routes/dashboard-routes'
import { Header } from '../../components/header/header';
import toast from 'react-hot-toast';
import HeaderLogo from './../../assets/images/ampere-logo.jpeg';
import { GET_APP_INFO } from '../../app/services/common.service';

export const Dashboard = () => {
    const [body, setBody] = useState<any>("inside-bg");
    const [toggleIcon, setToggleIcon] = useState<any>("nav_toggler");
    const [header, setHeader] = useState<any>("header");
    const [nav, setNav] = useState<string>("l-navbar");
    const [icon, setIcon] = useState<string>("bx bx-menu");
    const [isOffCanvasOpen, setIsOffCanvasOpen] = useState<boolean>(false);
    const [userRole, setUserRole] = useState("");
    const [navItems, setNavItems] = useState([]);
    const navigation = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [appInfoData, setAppInfoData] = useState<any>([]);
    const username = sessionStorage.getItem('user_name');

    useEffect(() => {
        const userToken: any = sessionStorage.getItem("jwt_token");
        if (!userToken) {
            navigation("/");
        } else {
            const userRole = sessionStorage.getItem('user_role');
            handleMenusItems(userRole);
            getAppInfo();
        }
    }, [])

    const getAppInfo = () => {
        setLoading(true);
        GET_APP_INFO().then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    sessionStorage.setItem("s3_url", res?.data?.s3_url)
                    setAppInfoData(res?.data)
                    setLoading(false);
                }
            }
        });
    }

    const navToggle = (): void => {
        toggleIcon === "nav_toggler" ? setToggleIcon("nav_toggler toggle") : setToggleIcon("nav_toggler");
        body === "inside-bg" ? setBody("body-pd") : setBody("inside-bg");
        header === "header" ? setHeader("header body-pd") : setHeader("header");
        nav === "l-navbar" ? setNav("l-navbar show") : setNav("l-navbar");
        icon === "bx bx-menu" ? setIcon("bx bx-x") : setIcon("bx bx-menu");
    }

    const handleMenusItems = (roleData: any) => {
        let navItems: any = [];
        switch (roleData) {
            case 'SUPERADMIN':
                navItems = [
                    { path: '/dashboard/view', icon: 'bi bi-grid', name: 'Dashboard' },
                    { path: '/dashboard/customers', icon: 'bi bi-people', name: 'Customers' },
                    { path: '/dashboard/content', icon: 'bi bi-tools', name: 'content' },
                    { path: '/dashboard/payment-partners', icon: 'bi bi-diagram-3', name: 'Payment Partners' },
                    { path: '/dashboard/settings', icon: 'bi bi-gear', name: 'Settings' },
                    { path: '/dashboard/reports', icon: 'bi bi-layout-text-sidebar-reverse', name: 'Reports' },
                    { path: '/dashboard/tools', icon: 'bi bi-tools', name: 'Tools' },
                    { path: '/dashboard/contact-us', icon: 'bi bi-people', name: 'Contact Us List' },
                ];
                setNavItems(navItems);
                break;
            case 'CUSTOMER':
                navItems = [
                    { path: '/dashboard/view', icon: 'bi bi-grid', name: 'Dashboard' },
                    // { path: '/dashboard/subscription', icon: 'bi bi-tools', name: 'Subscription Configuration' },
                    { path: '/dashboard/sites', icon: 'bi bi-diagram-2', name: 'Sites' },
                    { path: '/dashboard/reports/subscribers', icon: 'bi bi-layout-text-sidebar-reverse', name: 'Reports' },
                    { path: '/dashboard/tools', icon: 'bi bi-tools', name: 'Tools' },
                ];
                setNavItems(navItems);
                break;
            case 'INDIVIDUAL_CUSTOMER':
                navItems = [
                    { path: '/dashboard/api-integrations', icon: 'bi bi-diagram-2', name: 'API Integartions' },

                ];
                setNavItems(navItems);
                break;
            default:
                break;
        }
    }

    const onClickLogout = () => {
        navigation("/")
        sessionStorage.clear();
        toast.success("Logged out successfully", { position: 'top-right' })
    }

    const onShowMobileSideMenu = () => {
        setIsOffCanvasOpen(true);
    }

    const onNavLinkClick = () => {
        setIsOffCanvasOpen(false);
    };

    return (
        <div>
            <body className={body}>
                <div className="py-2">
                    <header className={`${header}  border-2 `} id="header">
                        <div className="header_toggle" onClick={navToggle}> <i className={icon} id="header-toggle"></i> </div>
                        <h2 className='text-primary text-start mx-md-auto nav_logo mb-0 d-md-none bg-transparent ps-0'>
                            <img src={HeaderLogo} alt="" className='main-logo rounded-3' />
                        </h2>
                        <div className='d-md-none ms-auto'>
                            <button className="bg-transparent border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" onClick={onShowMobileSideMenu}><i className={`bi bi-list fs-1 mx-2 fw-bold`} style={{ color: "white" }}></i></button>
                            <div className={`offcanvas offcanvas-start w-75 ${isOffCanvasOpen ? "" : "offcanvas-backdrop-show"}`} data-bs-scroll="true" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel" style={{ display: isOffCanvasOpen ? "block" : "none" }}>
                                <button type="button" className="btn-close text-reset mt-3 ms-auto text-dark position-relative" style={{ left: "90%" }} data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                <div className="offcanvas-body">
                                    <>
                                        <div className='mb-4'>
                                            <img src={HeaderLogo} className='rounded-3 w-100' style={{ maxWidth: "100%", height: "25vh", objectFit: "contain" }} />
                                        </div>
                                        {navItems.map((item: any, index: number) => (
                                            <NavLink key={index} to={item.path} className="nav_link nav-element text-decoration-none" onClick={onNavLinkClick}>
                                                <span>{item?.icon === null ? <img src={item?.image} className="bg-white" style={{ width: '20px', height: "20px" }} /> : <i className={item.icon}></i>}</span>
                                                <span className="nav_name">{item.name}</span>
                                            </NavLink>
                                        ))}
                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="me-md-auto ms-md-3 d-none d-md-block">
                        </div>
                        <div className="d-none d-md-flex align-items-center">
                            <div className='d-flex justify-content-end'>
                                <a href="https://docs.ampere.digital/" target="_blank" className='me-2 text-white my-3 text-decoration-none'>Integration Document</a>
                                <div className='me-2 text-white my-3'>| {username}</div>
                                <div className="dropdown px-0">
                                    <button className="btn btn-secondary bg-transparent border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className='bi bi-person-circle text-white fs-3 me-1'></i> <span style={{ fontSize: "13px", color: "white" }}> </span>  <span><i className="bi bi-chevron-down" style={{ color: "white" }}></i> </span>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-position">
                                        {/* <li><span className="dropdown-item cursor-pointer text-dark "><i className="bi bi-person" ></i><span className='ms-2'>{username}</span></span></li> */}
                                        <li className='dropdown-item cursor-pointer' onClick={onClickLogout}><span className='me-2'><i className="bi bi-box-arrow-right" ></i></span>Logout</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className={nav} id="nav-bar">
                        <nav className="navinstitute">
                            <div>
                                <h2 className='nav_logo text-primary mx-auto'>
                                    <img src={HeaderLogo} alt="" className='header-logo rounded-3' />
                                </h2>
                                <div className="nav_list">
                                    {navItems.map((item: any, index: number) => (
                                        <NavLink key={index} to={item.path} className="nav_link nav-element text-decoration-none">
                                            {item.icon !== null ? (
                                                <span><i className={item.icon}></i></span>
                                            ) : (
                                                <span><img src={item.image} alt="Nav Image" className="nav-icon" /></span>
                                            )}
                                            <span className="nav_name">{item.name}</span>
                                        </NavLink>
                                    ))}
                                </div>
                            </div>
                        </nav>
                    </div>
                    {/* {main content} */}
                    <div className='content-bg-image'>
                        <div className="content-body ">
                            <div className="container-fluid">
                                <div className="main-title ">
                                    <DashboardRoutes />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </div>
    )
}
