import React, { useEffect, useState } from 'react'
import { DataTable } from '../../../../components/data-table'
import { DynamicForm } from '../../../../components/form-builder/form'
import { SiteReportsDataGridCols } from './site-reports-data-grid-cols';
import Chart from "react-apexcharts";
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_SITES_REPORTS } from '../../../../app/services/reports.service';
import { Loader } from '../../../../components/loader';
import { GET_USERS } from '../../../../app/services/users.service';
import { GET_SITES } from '../../../../app/services/sites.service';
import { GET_SUBSCRIPTIONS } from '../../../../app/services/subscriptions.service';
import { SubscriptionTypesReportsDataGridCols } from './subscription_types-data-grid-cols';
import { PaymentPartnerReportsDataGridCols } from './payment-partners-data-grid-cols';
import * as XLSX from 'xlsx';
import toast from 'react-hot-toast';

export const SiteReports = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false);
    const [showPaymentPartnerDetailsPopup, setShowPaymentPartnerDetailsPopup] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);
    const [customerNames, setCustomerNames] = useState<string[]>([]);
    const [subscriptionAmounts, setSubscriptionAmounts] = useState<number[]>([]);
    const [totalSubscriptionCounts, setTotalSubscriptionCounts] = useState<number[]>([]);
    const [subscriptionTypesData, setSubscriptionTypesData] = useState<any>();
    const [paymentPartnersData, setPaymentPartnersData] = useState<any>();
    const [selectedData, setSelectedData] = useState<any>();
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });
    const navigate = useNavigate();
    const userRole = sessionStorage?.getItem("user_role");
    const userUuid = sessionStorage?.getItem("user_uuid");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const customerUuid = queryParams.get('customerUuid');

    const [reportsSearchForm, setReportsSearchForm] = useState<any>([
        {
            title: "dropdown",
            type: "text",
            label: "Customers",
            name: "customer_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select customer",
            error: "Please select customer",
            hideMandatoryStar: true,
            isParentDropDown: true,
            isApiCall: false,
        },
        {
            title: "dropdown",
            type: "text",
            label: "Sites",
            name: "site_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select site",
            error: "Please select site",
            hideMandatoryStar: true,
            isParentDropDown: true,
            isApiCall: false,
        },
        {
            title: "dropdown",
            type: "text",
            label: "Subscriptions",
            name: "subscription_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select subscription",
            error: "Please select subscription",
            hideMandatoryStar: true,
            isParentDropDown: false,
            isApiCall: false,
        },
        {
            title: "input",
            type: "date",
            label: "From Date",
            name: "from_dt",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select from date",
            error: "Please select from date",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "date",
            label: "To Date",
            name: "to_dt",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select to date",
            error: "Please select to date",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-4 d-flex justify-content-start mt-3 pt-3",
            buttons: [
                {
                    title: 'Search',
                    action: 'add',
                    class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-1'
                }
            ]
        },
    ]);

    const [optionsMixedChart, setOptionsMixedChart] = useState({
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "50%",
            },
        },
        stroke: {
            width: [4, 0, 0],
        },
        xaxis: {
            categories: customerNames,
        },
        markers: {
            size: 6,
            strokeWidth: 3,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 8,
            },
        },
        yaxis: {
            tickAmount: 5,
            min: 0,
            // max: 5000,
            labels: {
                formatter: function (value: any) {
                    return Math.round(value).toString(); // Rounds to nearest integer
                },
            },
        },
        plugins: {
            legend: {
                position: "top", // Placing legend at the top
            },
        },
    });

    const [seriesMixedChart, setSeriesMixedChart] = useState([
        {
            name: "Total Subscription Amount",
            type: "line",
            data: subscriptionAmounts,
        },
        {
            name: "Total Subscriptions",
            type: "column",
            data: totalSubscriptionCounts,
        },
    ]);

    useEffect(() => {
        if (userRole === "CUSTOMER") {
            const updatedForm = reportsSearchForm;
            updatedForm.splice(0, 1); // Remove the first element
            setReportsSearchForm([...updatedForm]);
        }
    }, [userRole]);

    useEffect(() => {
        const data = reportsSearchForm;
        data[0].value = customerUuid
        setReportsSearchForm([...data])
        if (customerUuid) {
            getSites(customerUuid);
        }
    }, [customerUuid])

    useEffect(() => {
        getSitesReports(pageCount);
        getCustomers();
        if (userRole === "CUSTOMER") {
            getSites(userUuid);
        }
    }, [])

    const getSitesReports = (pageData: any) => {
        setLoading(true);
        pageData.customer_uuid = userRole === "CUSTOMER" ? userUuid : customerUuid
        GET_SITES_REPORTS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    const sitesData = res.data.rows;
                    const names: any = sitesData.map((item: any) => item.site_name);
                    const subscriptionAmounts: any = sitesData.map((item: any) => item.total_amount);
                    const totalSubscriptionCounts: any = sitesData.map((item: any) => item.total_subscriptions);

                    setCustomerNames([...names]);
                    setSubscriptionAmounts([...subscriptionAmounts]);
                    setTotalSubscriptionCounts([...totalSubscriptionCounts]);

                    setOptionsMixedChart((prevOptions: any) => ({
                        ...prevOptions,
                        xaxis: {
                            ...prevOptions.xaxis,
                            categories: names
                        }
                    }));

                    setSeriesMixedChart([
                        { name: "Total Subscription Amount", type: "line", data: subscriptionAmounts },
                        { name: "Total Subscriptions", type: "column", data: totalSubscriptionCounts },
                    ]);
                    setLoading(false);
                }
            }
        });
    }

    const getCustomers = () => {
        setLoading(true);
        GET_USERS({ limit: 1000, page: 0 }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    if (userRole !== "CUSTOMER") {
                        const customers = [];
                        for (let i = 0; i < response?.length; i++) {
                            const element = response[i];
                            customers.push({ label: element?.name, value: element?.uuid })
                        }
                        const data = reportsSearchForm
                        data[0].option = customers
                        setReportsSearchForm([...data])
                    }
                    setLoading(false);
                }
            }
        });
    }

    const getSites = (customerUuid: any) => {
        setLoading(true);
        GET_SITES({ limit: 1000, page: 0, customer_uuid: userRole === "CUSTOMER" ? userUuid : customerUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const sitesData = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        sitesData.push({ label: element?.code, value: element?.uuid })
                    }
                    const data = reportsSearchForm
                    if (userRole === "SUPERADMIN") {
                        data[1].option = sitesData
                    } else if (userRole === "CUSTOMER") {
                        data[0].option = sitesData
                    }
                    setReportsSearchForm([...data])
                    setLoading(false);
                }
            }
        });
    }

    const getSubscriptions = (siteUuid: any) => {
        setLoading(true);
        GET_SUBSCRIPTIONS({ limit: 1000, page: 0, site_uuid: siteUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const subscriptionsData = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        subscriptionsData.push({ label: element?.plan_name, value: element?.uuid })
                    }
                    const data = reportsSearchForm
                    // data[].option = subscriptionsData
                    if (userRole === "SUPERADMIN") {
                        data[2].option = subscriptionsData
                    } else if (userRole === "CUSTOMER") {
                        data[1].option = subscriptionsData
                    }
                    setReportsSearchForm([...data])
                    setLoading(false);
                }
            }
        });
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const pageData = pageCount;
            pageData.page = 0
            const formDataObj = { ...pageData, ...data?.formData };
            delete formDataObj['undefined'];
            for (var propName in formDataObj) {
                if (!formDataObj[propName]) {
                    delete formDataObj[propName];
                }
            }
            const searchData = formDataObj
            searchData.page = 0
            setPageCount({ ...searchData })
            getSitesReports(formDataObj)
        } else if (data?.type === "cancel") {
            const formData = reportsSearchForm;
            formData?.forEach((element: any) => {
                element.value = ""
            })
            setReportsSearchForm([...formData]);
            const pageData = {
                limit: 10,
                page: 0,
            }
            getSitesReports(pageData);
        }
    };

    const onChangeDropdown = (event: any) => {
        if (event.name === 'customer_uuid') {
            getSites(event.value)
        } else if (event.name === 'site_uuid') {
            getSubscriptions(event.value)
        }
    }

    const onActions = (data: any) => {
        if (data.type === "link") {
            navigate(`/dashboard/reports/site-reports/${data.data.site_uuid}/subscriptions`);
        } else if (data.type === "Subscription Types") {
            setSelectedData(data?.data)
            const subscriptionTypesData = { total: 10, rows: data?.data?.subscription_types }
            setSubscriptionTypesData(subscriptionTypesData)
            setShowDetailsPopup(true)
        } else if (data?.type === "Payment Partner") {
            const paymentPartnersData = { total: 10, rows: data?.data?.payment_partners }
            setPaymentPartnersData(paymentPartnersData);
            setShowPaymentPartnerDetailsPopup(true);
        }
    };
    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getSitesReports(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getSitesReports(pageData);
    };

    const onSubscriptionTypesActions = (data: any) => { }
    const onPaymentPartnerActions = (data: any) => { }

    const onExportData = () => {
        setLoading(true);
        GET_SITES_REPORTS({ limit: 1000, page: 0 }).then((res: any) => {
            if (res.status === "error") {
                toast.error(res.message, { position: 'top-right' });
                setLoading(false);
            } else {
                if (res) {
                    const data = res.data?.rows;
                    const userData: any = [];
                    data.forEach((element: any) => {
                        userData.push(
                            {
                                "Customer Name": element?.customer_name,
                                "Site Name": element?.site_name,
                                "Total Subscriptions": element?.total_subscriptions,
                                "Total Subscription Amount": element?.total_amount,
                                "Payment Partner Amount": element?.pp_amount,
                                "Ampere Amount": element?.ampere_amount,
                                "Balance Amount": element?.balance_amount
                            }
                        );
                    })
                    const worksheet = XLSX.utils.json_to_sheet(userData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                    XLSX.writeFile(workbook, `site_reports.xlsx`);
                    setLoading(false);
                    setLoading(false);
                };
            }
        });
    }

    return (
        <div>
            <div className="sub-header-primary-color py-2">
                <div className="h-25 ms-2 text-white border-start border-3 border-success">
                    <div className="text-start ps-3">Site Reports</div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card rounded-0 bg-white">
                                <div className="card-body">
                                    <div className="row">
                                        <DynamicForm
                                            userFormData={reportsSearchForm}
                                            columns={3}
                                            cancelRoute={""}
                                            isEdit={false}
                                            custom_button_text={"Login"}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            onParentDropdownEvent={(e) => onChangeDropdown(e)}
                                            customClass={"display-block"}
                                            loading={""}
                                            isNotEmptyFormAfterSubmit={true}
                                        />
                                    </div>
                                </div>
                                <div className="px-2">
                                    <div>
                                        <div className="card-header text-start py-3 ">
                                            <i className="bi bi-postcard"></i>{" "}
                                            <span className="text-start "> Sites Table </span>
                                            <button className="float-end btn btn-primary py-1 border-0" onClick={onExportData}>
                                                {" "}
                                                Export <i className="bi bi-cloud-arrow-up"></i>
                                            </button>
                                        </div>
                                        <Loader LoadingReducer={loading} />
                                        <DataTable
                                            tableData={tableListData}
                                            TableCols={SiteReportsDataGridCols}
                                            onActions={onActions}
                                            onPageChange={onPageChange}
                                            onSelectPageChange={(e) => onSelectPage(e)}
                                            pageCount={pageCount}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mixed-chart">
                                            <div className="card-header text-start py-3 ">
                                                <i className="bi bi-postcard"></i>{" "}
                                                <span className="text-start "> Sites Chart </span>
                                            </div>
                                            {optionsMixedChart?.xaxis?.categories.length > 0 && (
                                                <Chart
                                                    series={seriesMixedChart}
                                                    options={optionsMixedChart}
                                                    type="line"
                                                    height={500}
                                                />
                                            )}
                                            {optionsMixedChart?.xaxis?.categories.length === 0 && (
                                                <div className='text-center py-5 my-5'>No Data Available</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {showDetailsPopup && (
                                    <>
                                        <div className={`modal modal-custom fade ${showDetailsPopup ? "show" : "false"}  ${showDetailsPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog modal-xl modal-dialog-centered">
                                                <div className="modal-content text-start">
                                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Subscription Types</strong></h1>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDetailsPopup(false)}></button>
                                                    </div>
                                                    <div className="modal-body pb-5">
                                                        <div className="row px-2">
                                                            <div className='col-md-12'>
                                                                <DataTable
                                                                    tableData={subscriptionTypesData}
                                                                    TableCols={SubscriptionTypesReportsDataGridCols}
                                                                    onActions={(e: any) => onSubscriptionTypesActions(e)}
                                                                    onPageChange={(e: any) => onPageChange(e)}
                                                                    onSelectPageChange={(e) => onSelectPage(e)}
                                                                    pageCount={pageCount}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {showPaymentPartnerDetailsPopup && (
                                    <>
                                        <div className={`modal modal-custom fade ${showPaymentPartnerDetailsPopup ? "show" : "false"}  ${showPaymentPartnerDetailsPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                                            <div className="modal-dialog modal-xl modal-dialog-centered">
                                                <div className="modal-content text-start">
                                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Payment Partners</strong></h1>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowPaymentPartnerDetailsPopup(false)}></button>
                                                    </div>
                                                    <div className="modal-body pb-5">
                                                        <div className="row px-2">
                                                            <div className='col-md-12'>
                                                                <DataTable
                                                                    tableData={paymentPartnersData}
                                                                    TableCols={PaymentPartnerReportsDataGridCols}
                                                                    onActions={(e: any) => onPaymentPartnerActions(e)}
                                                                    onPageChange={(e: any) => onPageChange(e)}
                                                                    onSelectPageChange={(e) => onSelectPage(e)}
                                                                    pageCount={pageCount}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
